import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'gatsby';
import Ionicon from 'react-ionicons';

import styles from './NotFound.module.scss';

const cx = classNames.bind(styles);

export default class NotFound extends Component {

  render() {
    return (
      <div className={cx('container')}>
        <img src={require('assets/images/misc/not-found.png')} alt="Not Found" className={cx('image')} />

        <div className={cx('title')}>페이지를 찾을 수 없습니다.</div>
        <div className={cx('paragraph')}>
          존재하지 않는 페이지이거나<br />요청하신 서비스가 정상처리 되지 않았습니다.
        </div>
        <Link to="/" className={cx('moveHome')}>
          <div className={cx('text')}>홈으로 가기</div>
          <Ionicon icon="ios-arrow-forward" fontSize="17px" className={cx('arrow')} />
        </Link>
      </div>
    );
  }
}